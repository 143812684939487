import packageInfo from '../package.json';

const envVars = process.env;

const config = {
	env: envVars.NODE_ENV,
	isDevelopment: envVars.NODE_ENV === 'development',
	isProduction: envVars.NODE_ENV === 'production',
	isTest: envVars.NODE_ENV === 'test',
	logger: {
		enabled: envVars.LOGGER_ENABLED,
		level: envVars.LOGGER_LEVEL
	},
	sentry: {
		dsn: window.env.SENTRY_DSN,
	},
	notificationLanguages: {
		english: 1,
		spanish: 2,
	},
	version: packageInfo.version,
	maxAppointmentAttachmentSize: 10485760,	
	privacyPolicyUrl: 'https://www.experian.com/privacy/index.html',
}

export default config
