import moment from 'moment';
import { getEffectiveNotificationValues } from './bookAppointment';
import careOrderStatusReasonType from '../../constants/careOrderStatusReasonTypeEnum';

/*
 * List of fields used by each notification:
 *
 * Email Confirmation => emailConfirmation (*), confirmationEmail
 * Third Party Email Confirmation => thirdPartyEmailConfirmation, thirdPartyEmail
 * Email Reminder => emailReminder, reminderEmail, emailRemindersHoursBefore
 * Voice Reminder => mobilePhoneCallReminder, mobilePhoneCallReminderNumber, mobilePhoneCallRemindersHoursBefore
 * Text Reminder => mobilePhoneTextReminder, mobilePhoneTextReminderNumber, mobilePhoneTextRemindersHoursBefore
 */
export const mapViewInfoToConfirmAppointmentDto = (state, props) => {
	const { contactInfo, patient, symptomDetails } = state.fields;
	const { activeCareOrderDetails, externalReferralOrderId, reservation, reservationDetails, session, token } = props;

	const notifications = getEffectiveNotificationValues(state.fields, props);

	const data = {
		//WHY DOES THIS CALL NEED RESERVATION DETAILS THAT CAN"T CHANGE, SHOULDN"T THE ENDPOINT BE ABLE TO HANDLE THIS FROM AN ID (WHY ALL CAPS DO I TYPE)
		details: {
			appointmentTypeId: reservationDetails.idAppointmentType,
			careOrderVisitIdentifier: activeCareOrderDetails.careOrderVisitIdentifier,
			clientIdentifier: '', // why is this hard coded as empty
			confirmationEmail: notifications.emailConfirmation.toEmail,
			decisionSupportSessionId: session.decisionSupportSessionId,
			correlationKey: session.correlationKey,
			customFieldValues: getCustomFieldValues(state.fields),
			emailConfirmation: notifications.emailConfirmation.shouldSendNotification,
			emailReminder: notifications.emailReminder.shouldSendNotification,
			emailRemindersHoursBefore: notifications.emailReminder.reminderLeadTimeHours, // NOTE: The option to change this isn't currently shown on CD2
			externalReferralOrderId: externalReferralOrderId,
			homePhoneCallReminder: notifications.voiceReminder.shouldSendNotification,
			homePhoneCallReminderNumber: notifications.voiceReminder.toPhoneNumber,
			homePhoneRemindersHoursBefore: notifications.voiceReminder.reminderLeadTimeHours, //NOTE: The option to change this isn't currently shown on CD2
			idInsurance: reservationDetails.idInsurance,
			idInsuranceState: reservationDetails.idInsuranceState,
			idLanguage: contactInfo.notificationLanguage,
			idPayorType: reservationDetails.idPayorType,
			idServiceSite: reservation.timeSlotRequestInfo.serviceSiteId,
			idSlot: 0, // not used
			managedReferringServiceId:
				activeCareOrderDetails.careOrderVisitIdentifier && activeCareOrderDetails.careOrderVisitIdentifier !== ''
					? activeCareOrderDetails.appointments[0].referringServiceId
					: state.internalReferringServiceId,
			// The Nighthawk Notification UI works differently than the legacy UI, can only send 1 voice and 1 text reminder, which can go to any phone number, so we just always use the home slots for voice and the mobile slots for text.
			mobilePhoneCallReminder: false, // not used, see above
			mobilePhoneCallReminderNumber: '', // not used, see above
			mobilePhoneCallRemindersHoursBefore: 0, // not used, see above
			mobilePhoneTextReminder: notifications.textReminder.shouldSendNotification,
			mobilePhoneTextReminderNumber: notifications.textReminder.toPhoneNumber,
			mobilePhoneTextRemindersHoursBefore: notifications.textReminder.reminderLeadTimeHours, //NOTE: The option to change this isn't currently shown on CD2
			reasonForAppointment: symptomDetails.reasonForVisit,
			referringOrganizationProviderId: state.referringOrganizationProviderId,
			reminderEmail: notifications.emailReminder.toEmail,
			reservationId: reservation.reservationId,
			slotEndDateTime: moment.utc(reservationDetails.appointmentEndAt).toISOString(),
			slotStartDateTime: moment.utc(reservationDetails.appointmentStartAt).toISOString(),
			thirdPartyEmail: notifications.thirdPartyEmailConfirmation.toEmail,
			thirdPartyEmailConfirmation: notifications.thirdPartyEmailConfirmation.shouldSendNotification,
			unmanagedReferringServiceName: state.unmanagedReferringServiceName,
			workboxId: props.session.workboxId,
			productInstanceConsumerId: props.auth.productInstanceId,
			hasRequestedWaitlist: state.isEarlierAppointmentRequested,
			idCareOrderStatusReason: careOrderStatusReasonType.PatientSelfScheduled
		},
		token: token,
		savePatientCriteria: {
			firstName: patient.firstName,
			middleName: patient.middleName,
			lastName: patient.lastName,
			addressLine1: patient.addressLine1,
			addressLine2: patient.addressLine2,
			cityName: patient.cityName,
			stateId: patient.stateId,
			zipCode: patient.zipCode,
			gender: patient.gender,
			dateOfBirth: patient.dateOfBirth,
			homePhone: contactInfo.homePhone,
			mobilePhone: contactInfo.mobilePhone,
			alternatePhone: contactInfo.alternatePhone,
			email: contactInfo.email,
			memberId: patient.memberId,
			groupNumber: patient.groupNumber,
			guardianName: patient.parentOrGuardianName,
			referenceId: props.patient.patientReferenceId,
		},
	};

	return data;
};

const getCustomFieldValues = (fields) => {
	let customFields = [];
	for (let fieldName in fields.customFieldsPreBook) {
		customFields.push({ key: fieldName, value: fields.customFieldsPreBook[fieldName] });
	}
	for (let fieldName in fields.customFieldsPostBook) {
		customFields.push({ key: fieldName, value: fields.customFieldsPostBook[fieldName] });
	}
	for (let fieldName in fields.customFieldsDecisionSupport) {
		customFields.push({ key: fieldName, value: fields.customFieldsDecisionSupport[fieldName] });
	}

	return customFields;
};
